import React from "react";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
function QuickSearchToolbar() {
    return (
        <Box
            sx={{
                p: 0.5,
                pb: 0,
            }}
        >
            <GridToolbarQuickFilter />
        </Box>
    );
}
export default function Basic(props) {
    let userAttributes = [];
    let i = 0;
    let columns = [];
    let rows = [];
    if (props.subscriberKeys !== undefined) {
        for (const key of Object.keys(props.subscriberKeys)) {
            for (const keynetwork of Object.keys(props.subscriberKeys[key])) {
                if (
                    props.subscriberKeys[key][keynetwork]["KeyTag"] !==
                    undefined &&
                    Object.keys(props.subscriberKeys[key][keynetwork]["VenueUnitPolicies"]).length !== 0
                ) {
                    userAttributes.push({
                        id: key,
                        keynetwork: keynetwork,
                        ssid: props.subscriberKeys[key]["ssid"],
                        keytag: props.subscriberKeys[key][keynetwork]["KeyTag"],
                        psk: props.subscriberKeys[key][keynetwork]["psk"],
                        subscriberKeys: props.subscriberKeys[key],
                        dateStart: Intl.DateTimeFormat("en-CA", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "numeric",
                            minute: "numeric",
                        }).format(
                            new Date(
                                props.subscriberKeys[key][keynetwork][
                                    "dateAddedUTC.ISO8601"
                                ]
                            )
                        ),
                    });
                }
                i = i + 1;
            }
        }
    }
    columns = [
        {
            headerName: "Network Name",
            field: "ssid",
            minWidth: 300,
        },
        {
            headerName: "Keytag",
            field: "keytag",
            minWidth: 300,
        },
        {
            headerName: "Action",
            field: "receipt",
            minWidth: 400,
            renderCell: (params) => (
                <div>
                    <Button
                        onClick={() =>
                          props.toggleShowWifiPassword(
                              params.row.id,
                              params.row.keynetwork
                          )
                        }
                        rel="noreferrer"
                    >
                        Show Wi-Fi Password
                    </Button>
                    <Button
                        onClick={() =>
                          props.openChangeWifiPassword(
                              params.row.id,
                              params.row.keynetwork,
                              params.row.ssid,
                              params.row.keytag,
                              params.row.subscriberKeys
                          )
                        }
                        rel="noreferrer"
                    >
                        Change Wi-Fi Password
                    </Button>
                </div>
            ),
        },
    ];
    rows = userAttributes;
    return (
        <div style={{ height: 300, width: "100%" }}>
            <DataGrid
                rows={rows}
                columns={columns}
                hideFooterPagination={true}
                hideFooter={true}
                getRowHeight={({ id, densityFactor }) => {
                    return 50 * densityFactor;
                }}
                slots={{ toolbar: QuickSearchToolbar }}
            />
        </div>
    );
}
