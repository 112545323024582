/* Find Deals screen */
import React, { Component, Suspense } from "react";
import axios from "../../axios";
import "../../brands/common/css/common.scss";
import Footer from "../../components/Common/Footer";
import _ from "lodash";
let SelectPlanComponent = React.lazy(() =>
    import(
        "../../brands/" +
            sessionStorage.getItem("customBrand") +
            "/components/CreateAccount/SelectPlan"
    )
);
const root = document.documentElement;
root?.style.setProperty(
    "--background-color",
    sessionStorage.getItem("background-color")
);
root?.style.setProperty(
    "--modal-background-color",
    sessionStorage.getItem("modal-background-color")
);
root?.style.setProperty(
    "--primary-text-color",
    sessionStorage.getItem("primary-text-color")
);
root?.style.setProperty(
    "--primary-color",
    sessionStorage.getItem("base-color")
);
root?.style.setProperty(
    "--secondary-color",
    sessionStorage.getItem("secondary-color")
);
root?.style.setProperty(
    "--secondary-text-color",
    sessionStorage.getItem("secondary-text-color")
);
root?.style.setProperty(
    "--border-color",
    sessionStorage.getItem("border-color")
);
root?.style.setProperty(
    "--primary-color-rgb",
    sessionStorage.getItem("base-color-rgb")
);
root?.style.setProperty("--link-color", sessionStorage.getItem("link-color"));
root?.style.setProperty(
    "--button-color",
    sessionStorage.getItem("button-color")
);
root?.style.setProperty("--button-text-color", "#FFF");
root?.style.setProperty(
    "--banner-color-1",
    sessionStorage.getItem("banner-color-1")
);
root?.style.setProperty(
    "--banner-color-2",
    sessionStorage.getItem("banner-color-2")
);
class SelectPlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            planList: [],
            loading: false,
        };
    }
    componentDidMount() {
        this.getPlans();
    }
    getPlans = () => {
        this.setState({
            loading: true,
        });
        let body;
        // if (sessionStorage.getItem("unit") !== null) {
        //   body = {
        //     UnitID_internal: sessionStorage.getItem("unit"),
        //     ShoppingCartEnabled: true,
        //   };
        // } else {
        //   body = {
        //     VenueID: sessionStorage.getItem("venueID"),
        //     ShoppingCartEnabled: true,
        //   };
        // }
        body = {
            VenueID: sessionStorage.getItem("venueID"),
            ShoppingCartEnabled: true,
        };
        // console.log(sessionStorage.getItem("unit"))
        let filteredServicePlans = [];
        axios
            .post(
                "/networkoperatorconsole/shoppingcart/listServicePlans",
                body,
                {
                    headers: {},
                }
            )
            .then((response) => {
                for (
                    var i = 0;
                    i < response.data["AvailableServicePlans"].length;
                    i++
                ) {
                    filteredServicePlans.push(
                        response.data["AvailableServicePlans"][i]
                    );
                }
                let sortedServicePlans = _(filteredServicePlans)
                    .map(function (v, k) {
                        return _.merge({}, v, { key: k });
                    })
                    .sortBy("BillingCyclePrice.USD")
                    .reverse() // sort by name
                    .value();
                this.setState({
                    planList: sortedServicePlans,
                });
                this.setState({
                    loading: false,
                });
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                });
                this.setState({ error: true });
            });
    };
    selectedPlan = (
        servicePlanKey,
        servicePlanID,
        stripePriceID,
        servicePlanName,
        billingCyclePrice,
        billingCycleRecurrence,
        fixedBillingDate,
        trialCycles
    ) => {
        sessionStorage.setItem("servicePlanKey", servicePlanKey);
        sessionStorage.setItem("servicePlanID", servicePlanID);
        sessionStorage.setItem("stripePriceID", stripePriceID);
        sessionStorage.setItem("servicePlanName", servicePlanName);
        sessionStorage.setItem("billingCyclePrice", billingCyclePrice);
        sessionStorage.setItem(
            "billingCycleRecurrence",
            billingCycleRecurrence
        );
        sessionStorage.setItem("fixedBillingDate", fixedBillingDate);
        sessionStorage.setItem("trialCycles", trialCycles);
    };
    render() {
        return (
            <>
                <Suspense
                    fallback={
                        <div
                            className="overlay"
                            id="overlay"
                            style={{ display: "block" }}
                        >
                            <div className="overlayMessage">
                                <div
                                    className="spinner-border spinner-border"
                                    role="status"
                                >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    }
                >
                    <SelectPlanComponent
                        planList={this.state.planList}
                        loading={this.state.loading}
                        selectedPlan={this.selectedPlan}
                        logo={this.props.logoLogin}
                        brand={this.props.brand}
                    />
                    <Footer />
                </Suspense>
            </>
        );
    }
}
export default SelectPlan;
